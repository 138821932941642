import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import configureRouter from './configureRouter'
import Page from '@/Page.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
    meta: {
      title: {
        zh: "登录",
        en: "login"
      },
      name: 'login'
    }
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
    meta: {
      title: {
        zh: "登录",
        en: "login"
      },
      name: 'login'
    }
  },
  {
    path: '/metamask',
    component: () => import(/* webpackChunkName: "metamask" */ '@/views/metamask/index.vue'),
    meta: {
      auth: false,////不登录同样可以访问
      title: {
        zh: "metamask",
        en: "metamask"
      },
      name: 'metamask'
    }
  },
  {
    path: '/page',
    component: Page,
    // redirect: '/home',
    redirect: '/suocangzhuanqu',
    name: 'Page',
    children: [
      // {
      //   path: '/home',
      //   name:'home',
      //   component: () =>import(/* webpackChunkName: "home" */ '@/views/home/home.vue'),
      //   meta: {
      //     title: {
      //       zh: "首页",
      //       en: "home"
      //     },
      //     name: 'home'
      //   }
      // },
      // {
      //   path: '/aboutme',
      //   name:'aboutme',
      //   component: () =>import(/* webpackChunkName: "aboutme" */ '@/views/aboutme/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "关于我们",
      //       en: "aboutme"
      //     },
      //     name: 'aboutme'
      //   }
      // },
      // {
      //   path: '/news',
      //   name:'news',
      //   component: () =>import(/* webpackChunkName: "news" */ '@/views/news/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "新闻之窗",
      //       en: "news"
      //     },
      //     name: 'news'
      //   }
      // },
      // {
      //   path: '/chanyemulu',
      //   name:'chanyemulu',
      //   component: () =>import(/* webpackChunkName: "chanyemulu" */ '@/views/chanyemulu/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "产业目录",
      //       en: "chanyemulu"
      //     },
      //     name: 'chanyemulu'
      //   }
      // },
      // {
      //   path: '/nft',
      //   name:'nft',
      //   component: () =>import(/* webpackChunkName: "nft" */ '@/views/nft/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "产业目录",
      //       en: "nft"
      //     },
      //     name: 'nft'
      //   }
      // },
      // {
      //   path: '/dianyingsan',
      //   name:'dianyingsan',
      //   component: () =>import(/* webpackChunkName: "dianyingsan" */ '@/views/dianyingsan/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "电影3.0",
      //       en: "dianyingsan"
      //     },
      //     name: 'dianyingsan'
      //   }
      // },
      // {
      //   path: '/quanweifabu',
      //   name:'quanweifabu',
      //   component: () =>import(/* webpackChunkName: "quanweifabu" */ '@/views/quanweifabu/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "权威发布",
      //       en: "quanweifabu"
      //     },
      //     name: 'quanweifabu'
      //   }
      // },
      // {
      //   path: '/quanzheng',
      //   name:'quanzheng',
      //   component: () =>import(/* webpackChunkName: "quanzheng" */ '@/views/quanzheng/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "权证ABC",
      //       en: "quanzheng"
      //     },
      //     name: 'quanzheng'
      //   }
      // },
      // {
      //   path: '/zhuanqu',
      //   name:'zhuanqu',
      //   component: () =>import(/* webpackChunkName: "zhuanqu" */ '@/views/zhuanqu/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "活动专区",
      //       en: "zhuanqu"
      //     },
      //     name: 'zhuanqu'
      //   }
      // },
      // {
      //   path: '/youjiangjingda',
      //   name:'youjiangjingda',
      //   component: () =>import(/* webpackChunkName: "youjiangjingda" */ '@/views/youjiangjingda/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "有奖竞答",
      //       en: "youjiangjingda"
      //     },
      //     name: 'youjiangjingda'
      //   }
      // },
      // {
      //   path: '/worhome',
      //   name:'worhome',
      //   component: () =>import(/* webpackChunkName: "worhome" */ '@/views/worhome/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "WOR星球",
      //       en: "worhome"
      //     },
      //     name: 'worhome'
      //   }
      // },
      // {
      //   path: '/paihangbang',
      //   name:'paihangbang',
      //   component: () =>import(/* webpackChunkName: "paihangbang" */ '@/views/paihangbang/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "排行榜",
      //       en: "paihangbang"
      //     },
      //     name: 'paihangbang'
      //   }
      // },
      // {
      //   path: '/shouhujiang',
      //   name:'shouhujiang',
      //   component: () =>import(/* webpackChunkName: "shouhujiang" */ '@/views/shouhujiang/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "守护奖",
      //       en: "shouhujiang"
      //     },
      //     name: 'shouhujiang'
      //   }
      // },
      // {
      //   path: '/huanlepintuan',
      //   name:'huanlepintuan',
      //   component: () =>import(/* webpackChunkName: "huanlepintuan" */ '@/views/huanlepintuan/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "欢乐拼团",
      //       en: "huanlepintuan"
      //     },
      //     name: 'huanlepintuan'
      //   }
      // },
      // {
      //   path: '/dati',
      //   name:'dati',
      //   component: () =>import(/* webpackChunkName: "dati" */ '@/views/dati/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "答题",
      //       en: "dati"
      //     },
      //     name: 'dati'
      //   }
      // },
      {
        path: '/suocangzhuanqu',
        name: 'suocangzhuanqu',
        component: () => import(/* webpackChunkName: "dati" */ '@/views/suocangzhuanqu/index.vue'),
        meta: {
          title: {
            zh: "锁仓专区",
            en: "suocangzhuanqu"
          },
          name: 'suocangzhuanqu'
        }
      },
      {
        path: '/friend',
        name: 'friend',
        component: () => import(/* webpackChunkName: "dati" */ '@/views/friend/index.vue'),
        meta: {
          title: {
            zh: "邀请好友",
            en: "friend"
          },
          name: 'friend'
        }
      },
      // {
      //   path: '/teamrenqisai',
      //   name: 'teamrenqisai',
      //   component: () => import(/* webpackChunkName: "teamrenqisai" */ '@/views/teamrenqisai/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "团队人气赛",
      //       en: "teamrenqisai"
      //     },
      //     name: 'teamrenqisai'
      //   }
      // },
      // {
      //   path: '/teamrenqisaidetail',
      //   name: 'teamrenqisaidetail',
      //   component: () => import(/* webpackChunkName: "teamrenqisaidetail" */ '@/views/teamrenqisaidetail/index.vue'),
      //   meta: {
      //     title: {
      //       zh: "团队人气赛详情",
      //       en: "teamrenqisaidetail"
      //     },
      //     name: 'teamrenqisaidetail'
      //   }
      // },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "PageNotFound" */ '@/views/PageNotFound/index.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
})

// Router Hooks
configureRouter(router)

export default router
