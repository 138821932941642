import { Router } from 'vue-router';
import wallet from "@/store/wallet";
import TokenWebiew from '@consenlabs-fe/webview';
function loginbycode(to, from, next) {
    let isLogin = wallet.state.isLogin;
    wallet.dispatch('setPath', {
        data: to.fullPath
    });
    if (to.meta.auth == false && (to.path != '/' || to.path != '/login')) {
        next();
    } else {
        if (to.path == '/' || to.path == '/login') {
            // if(isLogin){
            //     next('/home');
            // }else{
            //     next();
            // }
            next('/suocangzhuanqu');
        } else {
            if (to.path == '/worhome' || to.path == '/paihangbang' || to.path == '/shouhujiang') {
                if (isLogin) {
                    next();
                } else {
                    next('/zhuanqu');
                }
            } else {
                next();
            }
        }
    } 
    next()
}

export default function hook(router: Router): void {
    router.beforeEach(loginbycode)
}