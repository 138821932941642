import axios from 'axios';
import {Toast} from 'vant';
import { Session } from '@/utils/storage';
import Web3 from 'web3/dist/web3.min.js'
const web3 = new Web3((window as any).ethereum);
const address = await web3.eth.getAccounts();
// 配置新建一个 axios 实例
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL as any,
	timeout: 10000,
	headers: {address},
	// headers: {address:'0x6cfE7f1C4b7956BF8c2944f11FeFdC8C64748A79'},
});

// 添加请求拦截器
service.interceptors.request.use(
	(config:any) => {
		// 在发送请求之前做些什么 token
		if (Session.get('token')) {
			config.headers.common['token'] = `${Session.get('token')}`;
		}
		config.headers.common['Accept-Language'] = Session.get('lang')||'cn';
		return config;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
service.interceptors.response.use(
	(response) => {
		// 对响应数据做点什么
		const res = response.data;
		if (res.code && res.code !== 0) {
			// `token` 过期或者账号已在别处登录
			if (res.code === 401 || res.code === 4001) {
				Session.clear(); // 清除浏览器全部临时缓存
				window.location.href = '/auth/login'; // 去登录页
				Toast.clear()
				Toast({message:'你已被登出，请重新登录'});
			}
			return Promise.reject(service.interceptors.response);
		} else {
			return response.data;
		}
	},
	(error) => {
		Toast.clear();
		// 对响应错误做点什么
		if(error.request._url.include('tug/user/lpuser/addressSheQu')){
			return
		}
		if (error.message.indexOf('timeout') != -1 || error.message.indexOf('status code 404')) {
			Toast({
				message:'服务器异常'
			});
		} else if (error.message == 'Network Error') {
			Toast({
				message:'服务器异常'
			});
		} else {
			if (error.response.data) Toast({message:error.response.data.error});
			else Toast({message:'接口路径找不到'});
		}
		return Promise.reject(error);
	}
);

// 导出 axios 实例
export default service;