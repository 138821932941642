import { createStore } from 'vuex'
import {Session} from '@/utils/storage'
import {signIn,signInHuanle} from '@/api/login'
import router from '@/router/index'
import {Toast} from 'vant';
export default createStore({
  state: {
    isLogin:Session.get('isLogin')||false,
    userInfo:Session.get('userInfo')||{},
    name:'',
    address:Session.get('addressdata')?(Session.get('addressdata').address||''):'',
    list:[],
    pathurl:"",
    path:""
  },
  mutations: {
    getWallet(state,data){
      state.name = (data || {}).name
      state.address = (data || {}).address
    },
    getWalletList(state,list){
        state.list = list || []
    },
    getlogin(state,isLogin){
      state.isLogin = isLogin || []
    },
    getpathurl(state,data){
      state.pathurl = data || ''
    },
    getpath(state,data){
      state.path = data || ''
    },
    getUserInfo(state,userInfo){
      state.userInfo = userInfo || {}
    }
  },
  actions: {
    async setWallet({ commit,dispatch }, data:any) {
        commit('getWallet', data);
        let params={
          address:data.address,
          password:data.password
        }
        console.log(params);
        signIn(params).then((res:any)=>{
          console.log(res);
          if(res && res.status=='200'){
            let data = res.data||{};
            Session.set('token',data.token);
            commit('getlogin', true);
            Session.set('isLogin',true);
            router.push('/worhome')
          }else{
            Session.set('token',null);
            Session.set('isLogin',false);
            commit('getlogin', false);
            return Toast({message:res.title})
          }
        })
        
		},

    async setWallethuanle({ commit,dispatch }, data:any) {
      commit('getWallet', data);
      Session.set('token',data.token);
    },

    async setPathUrl({ commit },data) {
       commit('getpathurl', data.data);
		},   

    async setPath({ commit },data) {
      commit('getpath', data.data);
    },    
    async setWalletList({ commit }, data: object) {
      Session.set('addresslist',data)
			commit('getWalletList', data);
		},
  },
  modules: {},
})