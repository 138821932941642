import router from '@/router'
import { RouteRecordRaw } from 'vue-router'
let routes: Array<RouteRecordRaw> = [
  
]

const partnerFiles:any = require.context('./pages', true, /routes.ts$/);
////获取partnerFiles对象的所有key
partnerFiles.keys().forEach(key=>{
  routes = [...routes, ...partnerFiles(key).default]
  routes.forEach((item) => {
    router.addRoute(item)
  })
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
