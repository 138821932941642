import { createI18n } from 'vue-i18n';
import { Session } from '@/utils/storage';
export const i18n = createI18n({
  legacy: false,
  locale: Session.get('lang') || 'zh',
  messages: {
    en: {
      t1: 'Home',
      t2: 'About us',
      t3: 'Window news',
      t4: 'Property Catalog',
      t5: 'Cultural NFTs',
      t6: 'Movie 3.0',
      t7: 'authority publish',
      t8: 'Warrant ABC',
      t9: 'The British Virgin Islands "Hollywood Capital Group" adheres to the principle of "inclusive finance for the benefit of all living beings; Sharing economy, Si Li and people" corporate philosophy, always abide by the corporate culture of "love" and "self-discipline", determined to explore a new survival path suitable for individuals, enterprises, society and following the trend with the help of blockchain, meta-universe, Web 3.0, NFT, ChatGPT and other cutting-edge technologies that change human life, survival, work, communication, etc., supplemented by the carrier of real industry and capital market. In this way, let the blockchain be grounded and let the metaverse return to the world from the sky.',
      t10: 'The British Virgin Islands "Hollywood Capital Group" is a 35 entity enterprise groups in the United States, Thailand, China, Australia and Switzerland, covering biotechnology, financial media, digital finance, funds, securities investment, business aviation, blockchain, metaverse, movies, NFT, ChatGPT, large tourist attractions, hospitals, big health, new marketing, rice, wood and other fields.',
      t11: 'Warrior Warrant (WOR)"',
      t12: 'The Hollywood 3.0 Warrior Warrant (WOR), issued by Hollywood Capital Group, the BVI, has officially launched the Binance Pancake Exchange at 00:08 EST on February 26, 2023 (13:08 Beijing time on February 26, 2023).',
      t13: 'At 05:06 on March 8, 2023 New York time (18:06 Beijing time on March 8, 2023), the official website of "Hollywood Capital Group" in the British Virgin Islands (Chinese simplified version) will be officially opened, marking the official launch of "Hollywood 3.0 Warrior Warrant (WOR)" to the world.',
      t14: 'In the past few days, the media of China, the United States, Canada, India, France, Germany, Japan, South Korea, Russia, Thailand, Malaysia, Vietnam and other countries have paid attention to and reported on it. HERE, "HOLLYWOOD CAPITAL GROUP" WOULD LIKE TO EXPRESS ITS HEARTFELT THANKS TO MEDIA SUCH AS "MUSK BROTHER", "CLARISSA YORKE", "CRYPTOFIED1", "GOLDEN FINANCE", "GLOBAL NEWS ONLINE", "COIN CIRCLE LOUDSPEAKER", "BLOCKCHAIN BLUE OCEAN", "GODFATHER OF BITCOIN", "YOUNG SHUAI BLOCKCHAIN", "BLOCKCHAIN ARCHIVE", "FOUR-DIMENSIONAL FINANCE" AND "COIN GRANDPA TALKS ABOUT COIN".',
      t15: 'The British Virgin Islands "Hollywood Capital Group" is a 35 entity enterprise groups in the United States, Thailand, China, Australia and Switzerland, covering biotechnology, financial media, digital finance, funds, securities investment, business aviation, blockchain, metaverse, movies, NFT, ChatGPT, large tourist attractions, hospitals, big health, new marketing, rice, wood and other fields.',
      t16: 'American',
      t17: 'Thailand',
      t18: 'Chain HK',
      t19: 'Australian',
      t20: 'Swiss',
      t21: 'British Virgin',
      t22: "Hainan China",
      t23: '· "Metaverse Federation" in the United States (public welfare organization)',
      t24: '. "Metaverse Federal University" (non-profit organization)',
      t25: '. Washington International Institute of Biomedical Research (public welfare organization)',
      t26: '. American "Hollywood 3.0 Movie"',
      t27: '· American "Hollywood Biotechnology"',
      t28: '· "metaverse finance" in the United States',
      t29: '· US "Metaverse Headlines" (Chinese edition)',
      t30: '· US "Metaverse Headlines" (English Version)',
      t31: '· American "Metaverse Aviation"',
      t32: '· "AAM Aircraft Maintenance and Assembly" in the United States',
      t33: '· U.S. "Double Harvest World" (OTC)',
      t34: '· The "metaverse era" in the United States',
      t35: '· U.S. "Metaverse VR Experience Center" (under acquisition)',
      t36: '· "Hollywood Observation Deck" in the United States (under planning)',
      t37: '· US "Metaverse ChatGPT"',
      t38: '・Thailand "Amakusa Losa" (listed company industry)',
      t39: '· Thailand "Siam Legend" (listed company industry)',
      t40: "· Thailand's Global Medical Alliance (IPO soon)",
      t41: '・Thailand "Times Rice" (factory category)',
      t42: '. Thailand "Times Wood Industry" (factory category)',
      t43: '. Thailand "Utech International" (factory category)',
      t44: '. Thailand "TCD Securities Investment"',
      t45: '. Thailand "Youyou Kang Business"',
      t46: '. Hong Kong "Times Partners Fund"',
      t47: `. Hong Kong "The World's First Grass Partnership Fund"`,
      t48: `. Australia's "Metaverse Group Holdings"`,
      t49: `. Australia's "Metaverse Centralized Exchange"`,
      t50: `. Australia's "Times Public Chain"`,
      t51: `. Australia's "Metaverse Decentralized Exchange"`,
      t52: `. Australia's "Metaverse Digital Bank"`,
      t53: `. Australian "Metaverse Browser"`,
      t54: `. Australia "Kanghua Biohealth Care"`,
      t55: '. Swiss "metaverse era"',
      t56: '. British Viking "Chief Advisor to Manhattan"',
      t57: '. Hainan "Huanghuali and Agarwood Planting Base"',
      t58: 'The assets of the above 35 companies and the assets of the three Main Board listed companies that are being acquired by NASDAQ in the United States will be publicly released through the official website of this warrant by the international legal team of this warrant and a globally credible audit firm on June 6, 2023.',
      t59: `The "trio" composed of the founding promoter of "Hollywood Capital Group", the founding promoter of "Hollywood 3.0 Warrior Warrant", members of the world's first family and internationally renowned religious leaders, will break the stereotypes of 1.0 and 2.0 versions of NFT and lead the trend of NFT 3.0 era.`,
      t60: 'Click Enter',
      t61: '"Global NFT TOP30"',
      t62: 'The documentary will be completed in June',
      t63: '"Times Leader - Global NFT TOP30" piece flower',
      t64: 'THE DOCUMENTARY "ERA LEADER - GLOBAL NFT TOP 30" CO-PRODUCED BY "NFTY30 LLC" AND "HOLLYWOOD CAPITAL GROUP" IN THE BRITISH VIVI ISLANDS, "META ONE METAVERSE HEADLINES (Chinese VERSION)" and "META NEWS METAVERSE HEADLINES (ENGLISH VERSION)" WILL BE COMPLETED IN JUNE 2023 AND RELEASED TO THE WORLD.',
      t65: 'The documentary interviewed 30 celebrities who have made outstanding achievements and contributions in the NFT field around the world, including the founder of "Hollywood Capital Group" and the founding promoter of "Hollywood 3.0 Warrior Warrant" as the chairman and executive producer of "NFTY30 LLC", Spike Lee, winner of the Academy Award for Lifetime Achievement and chairman of the Cannes Film Festival in France, as the consultant and executive producer of "NFTY30 LLC", and Barry Alexander A. Lee, chairman of the Oscar documentary jury. Berwin serves as a board advisor and executive producer of NFTY30 LLC.',
      t66: '"Times Leader - Global NFT TOP30", aiming to be shortlisted for the 2024 Academy Award for Best Documentary!',
      t67: `At the same time, the movie "Reincarnation of Heaven's Way", which is fully planned and invested by the US "Hollywood 3.0 Movies" under the "Hollywood Capital Group", is also officially put on the agenda.`,
      t68: 'The times can lead',
      t69: 'Confessions on the occasion of the launch of "Good Foodwood 3.0 - Warrior Warrant"',
      t70: 'The times can lead',
      t71: '"Times Leader - Global NFT TOP30" piece flower',
      t72: '"Warrant WOR 20 Questions"',
      t73: `"Hollywood 3.0 Warrior Warrant (WOR)" has received inquiries from some participants since its launch on February 26. Today I have compiled 20 questions and answers, and replied with the help of the publisher's official website!`,
      t74: '1) When was the "Warrior Warrant (WOR)" launched? Who is the issuer?',
      t75: 'A: The "Mighty Warrant (WOR)" went live on February 26, 2023 at a price of 0.0168 USDT per warrant. However, the launch time of its official website (Chinese simplified version) is scheduled for March 8, so the official launch time for the world should be March 8, 2023. The issuer of the "Mighty Warrant (WOR)" is the British Virgin Islands "Hollywood Capital Group".',
      t76: '2) What is the purpose of issuing "Warrior Warrants (WOR)"?',
      t77: 'A: As the first company to propose the concept of "one stock currency" and put it into practice, Hollywood Capital Group aims to "create a new era of global equity economy".',
      t78: '3) What is the core value of "Warrior Warrant (WOR)"',
      t79: `A: "Mengshi Warrant (WOR)" is the world's first digital and tokenized "enterprise equity certificate" that organically integrates the decentralized characteristics of blockchain with the attributes of the metaverse virtual world, 3.0 movies, cultural NFTs, ChatGPT, physical industries, and capital markets.`,
      t80: '4) Is "Warrior Warrant (WOR)" a coin? Which form of existence?',
      t81: `A: The "Warrior Warrant (WOR)" is not a coin. It is a "tradable, transferable, collateral and dividend" corporate equity certificate issued by blockchain decentralized technology with the group's equity as the core value.`,
      t82: '5) The background of Hollywood Capital Group?',
      t83: `A: "Hollywood Capital Group" is an entity enterprise group with 35 businesses in the United States, Thailand, China, Australia, Switzerland and the British Virgin Islands, covering biotechnology, financial media, digital finance, funds, securities investment, business aviation, blockchain, metaverse, movies, NFT, ChatGPT, large-scale tourist attractions, hospitals, big health, new marketing, rice, wood and other fields. Among the 35 companies, there are also a number of listed companies.`,
      t84: '6) What is the shareholding structure of "Warrior Warrants (WOR)"?',
      t85: 'A: "Wor" is the equity of the group, a total of 10 billion shares, which will be released at a certain time according to the proportion of shareholders, and will also be put on the market in strict accordance with the plan in the white paper.',
      t86: '7) Is it to have a "Warrior Warrant (WOR)" to guard or add a pool?',
      t87: 'A: After obtaining the "Warrior Warrant (WOR)", you can inject the warrant you hold into the pool according to your personal situation to obtain the trading dividend of the warrant, or you can get the income after the rise by holding it.',
      t88: '8) What are the recent plans for "WOR)"?',
      t89: 'A: (1) Issue cultural NFTs to break the stereotypes of version 1.0 and 2.0 NFTs and lead the trend of NFT 3.0 era.',
      t90: `(2), shooting Hollywood 3.0 movies. The first documentary "Times Leader - Global NFT TOP30" will be completed and released globally in June 2023, and the second film "Reincarnation of Heaven's Dao" full of metaverse elements has also entered the planning stage.`,
      t91: `(3) Hold the "World's First ChatGPT Creative Competition".`,
      t92: '(4) Acquired three more listed companies on NASDAQ in the United States.',
      t93: '9) What is the actual circulation of "Warrior Warrant (WOR)"?',
      t94: 'A: As of August 31, 2023, only 4.27 million "Warrior Warrants (WOR)" have been released per month; Since September, only 4.52 million pieces have been released every month. The number of releases is not enough for the needs of a large household. Therefore, the market circulation is very small, so the market value of warrants is naturally very high.',
      t95: '10) Is WOR open source and completely decentralized?',
      t96: 'A: The "WOR" contract code has been open source and audited by CERTIK and ARMORS, and its contract does not have any "backdoors" and "channels", and is a completely decentralized project. In the current market, single audit is already difficult, and double audit can be described as even more difficult. Moreover, the double audit of "Warrior Warrant (WOR)" will soon be included in CG and CMC, and will also be on Skynet. This can be said to be difficult to get to the sky.',
      t97: `11) Will "Warrior Warrants (WOR)" be a centralized exchange?`,
      t98: 'A: The pizza exchange currently logged in by "WOR" is a decentralized exchange, and whether to log in to centralized trading in the future depends on market conditions.',
      t99: '12) How can issuers not harvest holders?',
      t100: 'A: The trading and release of "WOR" is fully transparent through blockchain technology and is jointly supervised by the whole network. The release of each warrant is subject to due diligence and review by the international team of lawyers of the warrant and the signing of the "contract".',
      t101: '13) Are all the addresses real?',
      t102: 'A: Binance Pancake Exchange is the most formal exchange, due to the decentralized and open characteristics of blockchain technology, the transaction records of each holding address are true and verifiable.',
      t103: `14) How did Hollywood Capital Group's assets flow into Wor?`,
      t104: 'Answer: After the profit report of the 35 real industries under the group is settled, the annual profit and the amount of profit used for dividends in the current year will be disclosed on the official website, and then the warrants will be injected, and then the dividends will be distributed to the holders through the weighting algorithm of market liquidity.',
      t105: '15) How does Hollywood Capital Group earn?',
      t106: `A: Hollywood Capital Group's 35 well-run companies will continue to generate profits and amplify them in the capital markets. At the same time, the development of 2.8 billion warrants and 1.2 billion industrial replacement can help the group to merge more high-quality assets.`,
      t107: '16) How to reflect the same rights of shares and currency, why is it called warrants, and what is the difference with coins?',
      t108: `A: "Mengshi Warrant (WOR)" takes the group's equity as the core value, through the blockchain decentralized technology and issued an open and transparent equity certificate, holding the warrant means holding the group's equity, and can obtain the group's profit dividends, while the currency does not have such dividends.`,
      t109: '17) How can WOR holders increase their earnings?',
      t110: 'Answer: "Warrior warrant (WOR) A: After obtaining the warrant, you can add the Mighty Warrant to the pool according to your personal circumstances to obtain the trading dividend of the warrant.',
      t111: '18) How to protect the income of "Warrior Warrant (WOR)"?',
      t112: `A: Hollywood Capital Group's 35 well-run companies will continue to generate profits, while the development of 2.8 billion warrants and 1.2 billion industrial replacements can help the group merge more high-quality assets to achieve a virtuous cycle in the industrial chain, and the rise of warrants is also a benefit.`,
      t113: '19) Do you recruit volunteers and push teams?',
      t114: 'A: The distribution and operations team currently has plans to recruit community volunteers, as well as plans to establish a new community for DAOs.',
      t115: '20) Does holding a "Warrior Warrant (WOR)" receive equity dividends?',
      t116: `A: Each warrant holder is a shareholder of the company and can participate in the annual corporate profit dividend. And the group promises: "Hollywood Capital Group will implement a guaranteed dividend in the fiscal year 2023~2024, regardless of whether the group's overall revenue in this fiscal year is a loss, or the after-tax profit is less than US$15 million, the Group will take out US$15 million for the released warrants for dividends."`,
      t117: 'Hollywood Gapital',
      t118: '好莱坞资本集团',
      t119: 'About us',
      t120: '关于我们',
      t121: 'Window the news',
      t122: '新闻之窗',
      t123: 'Industrial Catalogue',
      t124: '产业目录',
      t125: 'Culture NFT',
      t126: '文化NFT',
      t127: 'Movie 3.0',
      t128: '电影3.0',
      t129: 'Authoritative',
      t130: '权威发布',
      t131: 'Warrant ABC',
      t132: '权证ABC',
      t133: '* This warrant is strictly prohibited for all US legal persons and US taxpayers to participate in.',
      t134: '* 本权证，拒绝美国企业法人及美国纳税人参与！',
      t135: `I'am button`,
      t136: 'Activity zone',
      t1362: '活动专区',
      t137: 'Lock-up area',
      t138: 'Answer',
      t139: 'finish',
      t140: 'Next question',
      t141: 'Previous question',
      t142: 'Lock-up area',
      t143: 'Total hedges',
      t144: 'Total revenue',
      t145: 'Total individual locked-up volume',
      t146: 'Total proceeds are not withdrawn',
      t147: 'Purchase history',
      t148: 'My positions',
      t149: 'LP pool',
      t150: 'Earnings rewards',
      t151: 'sky',
      t152: 'Projected benefits',
      t153: 'Hedging volume',
      t154: 'Deposited',
      t155: 'Deposit',
      t156: 'Surplus can be saved',
      t157: 'balance',
      t158: 'Enter the deposit amount',
      t159: 'Each deposit will receive a unique token certificate, and after expiration, use the voucher to retrieve the assets, for your safety, please do not transfer the voucher to others.',
      t160: 'Exchange account',
      t161: 'Please enter',
      t162: 'Answer the question immediately',
      t163: 'Language',
      t164: 'Current total locked',
      t165: 'Total Reward Balance',
      t166: 'Ordinary area',
      t167: 'Reward Zone',
      t168: 'LP balance',
      t169: 'hedging LP',
      t170: 'Extractable LP',
      t171: 'Extraction',
      t172: 'Current account,Right:',
      t173: 'Go add liquidity',
      t174: 'Introduction to the WOR Project of the Mighty Warrant',
      t175: `As the first company to propose and put into practice the concept of "currency and shares", Hollywood Capital Group aims to "create a new era of global equity economy". WOR Warrior warrant is the world's first digital and tokenized "enterprise equity certificate" that organically integrates the decentralized characteristics of blockchain into the metaverse virtual world attributes, 3.0 movies, cultural NFTs, ChatGPT, real industries, and capital markets. `,
      t176: 'Project Advantage',
      t177: 'Institutional Investment Wallet',
      t178: 'Career Development Wallet',
      t179: 'Contract Initiator Wallet',
      t180: 'Industrial Replacement Wallet',
      t181: 'Operations and Management Team Wallet',
      t182: 'contract code',
      t183: 'Partner',
      t184: 'Estimated benefits',
      t185: 'Insufficient rewards!',
      t186: 'Deposit successful!',
      t187: 'LP balance is insufficient!',
      t188: 'Please enter the exchange account number',
      t189: "Exchange account submitted successfully!",
      t190: 'The input quantity cannot be greater than the LP balance!',
      t191: 'Community',
      t192: 'Subscription record',
      t193: 'Released monthly',
      t194: 'Subscription',
      t195: 'Join subscription',
      t196: 'Community code',
      t197: 'Please enter',
      t198: 'Subscribe to obtain 0wor (WOR lockup for ten months, release 10% each month)',
      t199: 'Join',
      t200: 'Current remaining subscriptions',
      t201: 'Subscription price',
      t202: 'Remaining release',
      t203: 'Released',
      t204: 'The subscription quantity is abnormal!',
      t205: 'The subscription quantity cannot be less than 0!',
      t206: 'The subscription quantity cannot be greater than the remaining subscription quantity!',
      t207: 'Extraction quantity',
      t208: 'Prize lock-up',
      t209: 'Community Subscription',
      t210: 'Number of subscriptions',
      t211: 'No data yet',
      t212: 'Consumption required',
      t213: 'USDT (WOR lock-up for ten months, releasing 10% every month)',
      t214: 'Not released yet, please try again later!',
      t215: 'Please enter the community code!',
      t216: ' Market Cap maintains cold wallets',
      t217: 'There are prizes',
      t218: '有奖竞答',
      t219: 'WOR Planet',
      t220: 'leaderboard',
      t221: 'Ranking',
      t222: 'wallet address',
      t223: 'Number of assists',
      t224: 'Guardian',
      t225: 'After the event, the last ten users will be rewarded according to their ranking',
      t226: 'Bottom Place',
      t227: 'User wallet address',
      t228: 'Rewards',
      t229: 'Wor',
      t230: 'Help WOR consensus and share the million jackpot!',
      t231: 'The initial prize pool is 100,000 WOR. Up to 1 million WOR.',
      t232: 'Play',
      t233: 'For every 5 WOR warrants spent, you will receive one booster ticket (booster tickets need to be purchased in advance, and the full amount unused after the event is completed). Use the booster ticket to make a booster once and get a booster card. The booster number is extended from 1 to a maximum of 180,000.',
      t234: 'The initial countdown of the event is 24 hours, and after the countdown is over, the prize is divided according to the prize distribution rules.',
      t235: 'Countdown rules: The initial countdown is 24 hours, and the countdown is extended by 10s for each assist (the booster cooldown time is 30s). The countdown ends, the event ends, and the winnings are settled.',
      t236: 'Bonus rules: Initial 100,000 WOR, for each boost received, the prize pool increases by 5 per WOR. When the prize pool reaches 1 million, the event ends and the bonus is settled.',
      t237: 'Partition',
      t238: 'I. Incentive Award',
      t239: '1. The top 10% of the users will share an average of 15% of the prize pool according to the consumption of booster cards',
      t240: "2. Users who help the last 10% will share an average of 15% of the prize pool according to the consumption of booster cards",
      t241: 'II. Guardian Award',
      t242: 'At the end of the event, the last 10 users who participated in the help won the Guardian Award. ',
      t243: '1、The last user to help the user will get 3% of the bonus pool',
      t244: '2, the penultimate user will receive 2% of the prize pool',
      t245: '3, the third from the bottom to help the user, get 1% of the prize pool',
      t246: '4, 4-5 from the bottom, get 0.5% of the prize pool',
      t247: '5, bottom 6-10, get 0.2% of the prize pool',
      t248: 'III. Ranking Award',
      t249: 'The 8 places with the most assists will win the ranking prize (if the number of times is the same, the ranking will be calculated according to the time of listing)',
      t250: '1. The first place to help the user, get 3% of the bonus pool',
      t251: '2, the second place helps the user, get 2% of the bonus pool',
      t252: '3, the third place to help the user, get 1% of the bonus pool',
      t253: '4, the fourth place to help the user, get 0.8% of the bonus pool',
      t254: '5, the fifth place helps the user, gets 0.6% of the bonus pool',
      t255: '6, the sixth place to help the user, get 0.3% of the prize pool',
      t256: '7, 7th place to help users, get 0.2% of the bonus pool',
      t257: '8, 8th booster user, get 0.1% of the prize pool',
      t258: 'IV. Dividend Award',
      t259: 'The remaining amount of the prize pool is distributed to users who have not received incentive prizes according to the holding of booster cards. ',
      t260: 'Note: Each booster on the account counts separately for the winnings. ',
      t261: 'Special',
      t262: '1. The interval between each assist for the same address is 30 seconds. ',
      t263: '2、After the event, all unused booster tickets will be refunded. ',
      t264: '3、When the prize pool amount > 180,000, you can use 5 booster tickets at a time. Get 5 booster cards (serial numbers). ',
      t265: 'When the prize pool amount > 300,000, 10 booster tickets can be used at a time. ',
      t266: 'When the prize pool amount > 500,000. You can use up to 20 booster tickets at a time. ',
      t267: 'When the prize pool amount > 910,000, the multi-boost function is turned off. ',
      t268: 'When using multiple booster tickets, the countdown is only extended once. ',
      t269: 'Other',
      t270: 'Due to the principle of fairness and justice, the whole event is open and transparent. The organizer requires the winners of the ranking award and guardian award to provide their real identity information when receiving the award, and publicize it on the whole network for participants to know. ',
      t271: 'Assist',
      t272: 'Introduction',
      t273: 'Booster tickets',
      t274: 'Assist',
      t275: 'Ticket',
      t276: 'list',
      t277: 'guard',
      t278: 'No one assists',
      t279: 'Success',
      t280: 'Game End Countdown',
      t281: 'Final Grand Prize Pool',
      t282: 'My Booster',
      t283: 'View',
      t284: 'My Booster Ticket',
      t285: 'Buy',
      t286: 'My bonus',
      t287: 'Buy a booster ticket',
      t288: 'Quantity purchased',
      t289: 'Quantity purchased',
      t290: 'Buy',
      t291: 'Booster Record',
      t292: 'Content',
      t293: 'Number of booster votes:',
      t294: 'Brand Code:',
      t295: 'Tips',
      t296: 'This bonus estimate is a reference figure after the end of the current campaign and does not represent your actual earnings. May change with the active process. This reference value does not include the Ranking Prize and the Guardian Award, and the winners of the Ranking Prize and Guardian Award will be announced after the event. ',
      t297: 'Purchase quantity format is wrong! ',
      t298: 'Purchase quantity cannot be empty! ',
      t299: 'Insufficient balance! ',
      t300: 'Operation successful! ',
      t301: 'Contribute to success! ',
      t302: `Can't choose!`,
      t303: 'Safety',
      t304: 'Password',
      t305: 'Please enter password',
      t306: 'OK',
      t307: 'Confirm Password',
      t308: 'Please enter password again',
      t309: 'Password length cannot be less than 6 digits! ',
      t310: 'Password must be numeric only!',
      t311: 'Password length cannot be less than 6 digits! ',
      t312: 'Password must be numeric only!',
      t313: 'Two password entries are inconsistent! ',
      t314: 'The password entered first time is the password for subsequent access, so please remember it!',
      t315: 'Vote Help Me First',
      t316: 'Number:',
      t317: 'Ranking',
      t318: 'Votes',
      t319: 'Share',
      t320: 'Voting',
      t321: 'Popularity Contest',
      t322: 'Can search for your team name or squad number',
      t323: 'Number:',
      t324: 'Votes:',
      t325: 'Ticket',
      t326: 'Share',
      t327: 'Voting',
      t328: 'Load more',
      t329: 'I also have a bottom line~',

      t330: 'Happy Together',
      t331: 'Create Squad',
      t332: 'Copy link',
      t333: 'Copy link',
      t334: 'Get Text',
      t335: 'Operation',
      t336: 'Yourself',
      t337: 'Exit',
      t338: 'Click the plus sign to upload squad photos and squad slogans',
      t339: 'Account Information Record',
      t340: 'Create a squad',
      t341: 'Fill in the squad name',
      t342: 'Create Squad',
      t343: 'Participation in activities',
      t344: 'Fill in Exchange Account Number',
      t345: 'Participation in activities',
      t346: 'upload',
      t347: 'Upload squad photos',
      t348: 'Squad Slogan',
      t349: 'Please enter the squad slogan (within 20 words, cannot be modified after uploading)',
      t350: 'OK'
    },
    zh: {
      t1: '首页',
      t2: '关于我们',
      t3: '新闻之窗',
      t4: '产业目录',
      t5: '文化NFT',
      t6: '电影3.0',
      t7: '权威发布',
      t8: '权证ABC',
      t9: '英属维京群岛“好莱坞资本集团”，秉持“普惠金融，惠及众生；共享经济，思利及人”的企业理念，永远恪守“爱”与“自律”的企业文化，立志在百年未有之大变局时期，借助区块链、元宇宙、Web3.0、NFT、ChatGPT等改变人类生活、生存、工作、交往等方式的前沿技术，辅之以实体产业、资本市场的载体，探索一条适合个人、适合企业、适应社会、顺乎潮流的全新的生存之路。以此，让区块链接地气，让元宇宙从天上回到人间。',
      t10: '英属维京群岛“好莱坞资本集团”，系在美国、泰国、中国、澳洲、瑞士拥有35家业务涵盖生物科技、融媒体、数字金融、基金、证劵投资、商务航空、区块链、元宇宙、电影、NFT、ChatGPT、大型旅游景区、医院、大健康、新营销、米业、木业等多领域的实体企业集团。',
      t11: '全球关注“猛士权证(WOR)”上线',
      t12: '由英属维京群岛“好莱坞资本集团”发行的“好莱坞3.0·猛士权证(WOR)”已于美国东部时间2023年2月26日00:08分(中国北京时间2023年2月26日13:08分)正式上线币安“薄饼交易所”。',
      t13: '美国纽约时间2023年3月8日05:06分(中国北京时间2023年3月8日18:06分)，英属维京群岛“好莱坞资本集团”的官网(中文简体版)将正式开通，标志着“好莱坞3.0·猛士权证(WOR)”正式面向全球上线。',
      t14: '连日来，中国、美国、加拿大、印度、法国、德国、日本、韩国、俄罗斯、泰国、马来西亚、越南等多国媒体予以关注并报道。在此，“好莱坞资本集团”谨向《马斯克弟弟》、《CLARISSA YORKE》、《CRYPTOFIED1》、《金色财经》、《环球新闻在线》、《币圈大喇叭》、《区块链蓝海》、《比特币教父》、《少帅区块链》、《区块链档案》、《四维财经》及《币姥爷谈币》等媒体致以衷心的感谢。',
      t15: '英属维京群岛“好莱坞资本集团”，系在美国、泰国、中国、澳洲、瑞士拥有35家业务涵盖生物科技、融媒体、数字金融、基金、证劵投资、商务航空、区块链、元宇宙、电影、NFT、ChatGPT、大型旅游景区、医院、大健康、新营销、米业、木业等多领域的实体企业集团。',
      t16: '美国部分',
      t17: '泰国部分',
      t18: '中国香港部分',
      t19: '澳洲部分',
      t20: '瑞士部分',
      t21: '英属维京群岛',
      t22: "中国海南部分",
      t23: '·美国“元宇宙联邦”（公益机构）',
      t24: '·美国“元宇宙联邦大学”（公益机构）',
      t25: '·美国“华盛顿国际生物医学研究院”（公益机构）',
      t26: '·美国“好莱坞3.0电影”',
      t27: '·美国“好莱坞生物科技”',
      t28: '·美国“元宇宙金融”',
      t29: '·美国“元宇宙头条”(中文版)',
      t30: '·美国“元宇宙头条”(英文版)',
      t31: '·美国“元宇宙航空”',
      t32: '·美国“AAM飞机维修及组装”',
      t33: '·美国“双丰收世界”（OTC）',
      t34: "·美国“元宇宙时代”",
      t35: '·美国“元宇宙VR体验中心”（收购中）',
      t36: '·美国“好莱坞观景台”（规划中）',
      t37: '·美国“元宇宙ChatGPT”',
      t38: '·泰国“天草洛莎”（上市公司产业）',
      t39: '·泰国“暹罗传奇”（上市公司产业）',
      t40: '·泰国“环球医疗联盟”（即将IPO）',
      t41: '·泰国“时代米业”（工厂类）',
      t42: "·泰国“时代木业”（工厂类）",
      t43: '·泰国“优泰国际”（工厂类）',
      t44: '·泰国“TCD证券投资”',
      t45: '·泰国“优你康商务”',
      t46: '·香港“时代合伙人基金”',
      t47: '·香港“天下第一草合伙基金”',
      t48: '·澳洲“元宇宙集团控股”',
      t49: '·澳洲“元宇宙中心化交易所”',
      t50: '·澳洲“时代公链”',
      t51: '·澳洲“元宇宙去中心化交易所”',
      t52: '·澳洲“元宇宙数字银行”',
      t53: '·澳洲“元宇宙浏览器”',
      t54: '·澳洲“康华生物保健”',
      t55: '·瑞士“元宇宙时代”',
      t56: '·英属维京“曼哈顿首席顾问”',
      t57: '·海南“黄花梨及沉香种植基地”',
      t58: '以上35家企业的资产及正在美国纳斯达克进行收购的3家主板上市公司的资产，将于2023年6月6日由本权证的国际化律师团队、具全球公信力的审计事务所连袂透过本权证的官网进行公开发布。',
      t59: '由“好莱坞资本集团”创始发起人、“好莱坞3.0·猛士权证”创始发起人连袂世界第一家族成员及国际著名宗教领袖组成的“三人组合”，将打破1.0、2.0版NFT的陈规，引领NFT3.0时代的风气之先。',
      t60: '点击进入',
      t61: '《时代引领者——全球 NFT TOP30》',
      t62: '纪录片将于六月杀青',
      t63: '《时代引领者——全球NFT TOP30》片花',
      t64: '由美国“NFTY30 LLC”和英属维京群岛“好莱坞资本集团”辖下美国“好莱坞3.0电影”、《META ONE 元宇宙头条(中文版)》、《META NEWS 元宇宙头条(英文版)》联合拍摄的《时代引领者——全球NFT TOP30》纪录片将于2023年6月杀青并面向全球发行。',
      t65: '该纪录片实地釆访了全球在NFT领域有卓越建树和贡献的30位大咖，由“好莱坞资本集团”发起创始人、“好莱坞3.0·猛士权证”创始发起人出任“NFTY30 LLC”董事局主席兼执行制片人，奥斯卡终身成就奖获得者、法国戛纳电影节主席斯派克·李出任“NFTY30 LLC”董事局顾问兼执行制片人，奥斯卡纪录片评审委员会主席巴里·亚历山大·柏温出任“NFTY30 LLC”董事局顾问兼执行制片人。',
      t66: '《时代引领者——全球NFT TOP30》，志在入围2024年奥斯卡最佳纪录片奖！',
      t67: '与此同时，由“好莱坞资本集团”辖下的美国“好莱坞3.0电影”全权筹划并投资的充满元宇宙元素的电影《天道轮回》也正式提上了议事日程。',
      t68: '时代可以引领',
      t69: '“好菜坞 3.0-猛士权证”上线之际的自白',
      t70: '时代可以引领',
      t71: '《时代引领者——全球NFT TOP30》片花',
      t72: '《猛士权证WOR 20问》',
      t73: '“好莱坞3.0·猛士权证(WOR)”自2月26日上线以来，收到一些参与者的咨询。今整理出20个      问答，借助发行方的官网一并回复！',
      t74: '1）“猛士权证(WOR)”是什么时候上线的？发行方是谁？',
      t75: '答：“猛士权证(WOR)”于2023年2月26日以每枚权证0.0168个USDT的价格上线。但其官网(中文简体版)的开通时间定在3月8日，故面向全球的正式上线时间当为2023年3月8日。“猛士权证(WOR)”的发行方为英属维京群岛“好莱坞资本集团”。',
      t76: '2）发行“猛士权证(WOR)”的目的？',
      t77: '答：“好莱坞资本集团”作为首个提出“股币同一”概念并付诸于实践的企业，其目的在于“开创全球股权经济新时代”。',
      t78: '3）“猛士权证(WOR)”的核心价值是什么',
      t79: '答：“猛士权证(WOR)”，是全球第一个将区块链去中心化特征有机融合元宇宙虚拟世界属性、3.0 电影、文化型 NFT、ChatGPT、实体产业、资本市场为一炉的数字化、通证化的《企业股权证》。',
      t80: '4）“猛士权证(WOR)”是币吗？哪种存在形式？',
      t81: '答：“猛士权证(WOR)”不是币。它是以集团股权为核心价值，借由区块链去中心化技术而发行的“可交易、可转让、可抵押、可分红”的企业股权凭证。',
      t82: '5）好莱坞资本集团的背景？',
      t83: "答：“好莱坞资本集团”系在美国、泰国、中国、澳洲、瑞士、英属维京群岛拥有35家业务涵盖生物科技、融媒体、数字金融、基金、证劵投资、商务航空、区块链、元宇宙、电影、NFT、ChatGPT、大型旅游景区、医院、大健康、新营销、米业、木业等多领域的实体企业集团。35家企业中，还有多家上市公司。",
      t84: '6）“猛士权证(WOR)”的股权架构？',
      t85: '答：“猛士权证(WOR)”即为集团股权，总计发行100亿枚，按照股东的比例在一定的时间释放，亦会严格按照白皮书中的规划投放市场中。',
      t86: '7）拥有“猛士权证(WOR)”是守还是加池子？',
      t87: '答：获得“猛士权证(WOR)”后，可视个人情况，将持有的权证注入资金池中，以此来获得该权证的交易分红，也可以通过守住获得上涨后的收益。',
      t88: '8）“猛士权证(WOR)”最近有哪些规划？',
      t89: '答：(1)，发行文化NFT，以打破1.0、2.0版NFT的陈规，引领NFT3.0时代的风气之先。',
      t90: '(2)，拍摄好莱坞3.0电影。首部纪录片《时代引领者——全球NFT TOP30》将于2023年6月杀青并面向全球发行，第二部饱含元宇宙元素的电影《天道轮回》也进入策划阶段。',
      t91: '(3)，举办“世界首届ChatGPT创意大赛”。',
      t92: '(4)，在美国纳斯达克再收购三家上市公司。',
      t93: '9）“猛士权证(WOR)”实际流通量有多少？',
      t94: '答：至2023年8月31日止，“猛士权证(WOR)”每月才释放427万枚；9月始，每月也才释放452万枚。释放数还不够一个大户的需求。因此市场流通量很少，故权证的市场价值自然很高。',
      t95: '10）“猛士权证(WOR)”是否开源，是否完全去中心化？',
      t96: '答：“猛士权证(WOR)”合约代码已开源，并经过CERTIK和ARMORS双重审计，其合约不存在任何“后门”和“通道”，是完全去中心化的项目。当下行情，单审计已经很难，双审计可谓难上加难。而且，“猛士权证(WOR)”的双审计即将被CG和CMC收录，同时还将上《天网》。这，可以说是难于上青天。',
      t97: "11）“猛士权证(WOR)”会上中心化交易所吗？",
      t98: '答：“猛士权证(WOR)”目前所登录的薄饼交易所为去中心化交易所，未来是否登录中心化交易所需依据市场情况而定。',
      t99: '12）发行方如何做到不收割持有者？',
      t100: '答：“猛士权证(WOR)”的交易与释放，借由区块链技术完全透明并受到全网共同监管。每一枚权证的释放，均需经本权证的国际化律师团队尽职调查和审核并签立《合约》，何来收割？',
      t101: '13）持有地址都是真实的吗？',
      t102: '答：币安薄饼交易所是最正规的交易所，由于区块链技术去中心化、公开化的特点，每一位持有地址的交易记录均真实可查。',
      t103: '14）好莱坞资本集团的资产如何流入“猛士权证(WOR)”？',
      t104: '答：集团名下35家实体产业利润汇算清缴后，将在官网公开年度盈利情况与本年度用于分红的利润数额，然后注入权证，再通过市场流通量的加权算法，分红给持有者。',
      t105: '15）好莱坞资本集团如何收益？',
      t106: '答：好莱坞资本集团其名下35家运营情况良好的企业将持续产生利润，并在资本市场上放大。同时权证事业发展的28亿枚，以及产业置换的12亿枚，可助力集团兼并更多优质资产。',
      t107: '16）如何体现股币同权，为何叫权证，和币有什么不一样？',
      t108: '答：“猛士权证(WOR)”以集团股权为核心价值，借由区块链去中心化技术而发行的公开、透明的股权凭证，持有权证即持有集团股权，可获得集团利润分红，而币是没有这种分红的。',
      t109: '17）“猛士权证(WOR)”持有者如何提高收益？',
      t110: '答：“猛士权证(WOR)答：获得权证后，可视个人情况，将持有的猛士权证加池子，以此来获得该权证的交易分红。',
      t111: '18）如何保障“猛士权证(WOR)”的收益？',
      t112: '答：好莱坞资本集团其名下35家运营情况良好的企业将持续产生利润，同时权证事业发展的28亿枚，以及产业置换的12亿枚，可助力集团兼并更多优质资产，以实现产业链的良性循环，同时权证上涨也是收益。',
      t113: '19）是否招募志愿者和地推团队？',
      t114: '答：发行运营团队目前确有招募社区志愿者的计划，也有建立DAO新社群的计划。',
      t115: '20）持有“猛士权证(WOR)”是否得到股权分红？',
      t116: '答：每一位权证的持有者，均是公司的股东，可参与年度企业利润分红。且集团承诺：“好莱坞资本集团在 2023～2024 财政年度实行保底分红，无论本财政年度集团的整体营收是亏损，抑或税后赢利不足 1,500 万美元，集团均会拿出 1,500 万美元用于已释放出来的权证进行分红”。',
      t117: '好莱坞资本集团',
      t118: 'Hollywood Gapital Group',
      t119: '关于我们',
      t120: 'About us',
      t121: '新闻之窗',
      t122: 'Window to the news',
      t123: '产业目录',
      t124: 'Industrial Catalogue',
      t125: '文化NFT',
      t126: 'Culture NFT',
      t127: '电影3.0',
      t128: 'Movie 3.0',
      t129: '权威发布',
      t130: 'Authoritative release',
      t131: '权证ABC',
      t132: 'Warrant ABC',
      t133: '* 本权证，拒绝美国企业法人及美国纳税人参与！',
      t134: '* This warrant is strictly prohibited for all US legal persons and US taxpayers to participate in.',
      t135: '我是按钮',
      t136: '活动专区',
      t1362: 'Activity zone',
      t137: '锁仓专区',
      t138: '答题',
      t139: '完成',
      t140: '下一题',
      t141: '上一题',
      t142: '锁仓专区',
      t143: '总锁仓量',
      t144: '总收益',
      t145: '个人总锁仓量',
      t146: '未提取总收益',
      t147: '购买记录',
      t148: '我的持仓',
      t149: 'LP池',
      t150: '收益奖励',
      t151: '天',
      t152: '预计收益',
      t153: '锁仓量',
      t154: '已存入',
      t155: '存 入',
      t156: '剩余可存',
      t157: '余额',
      t158: '输入存入数量',
      t159: '每次存入均获得唯一的代币凭证，到期后凭借凭证取回资产，为了您的安全请不要将凭证转给他人。',
      t160: '交易所账号',
      t161: '请输入',
      t162: '立即答题',
      t163: '中英文切换',
      t164: '当前总锁仓',
      t165: '奖励总余额',
      t166: '普通区',
      t167: '奖励区',
      t168: 'LP余额',
      t169: '锁仓LP',
      t170: '可提取LP',
      t171: '提取',
      t172: '当前账号已完成答题，答对题数：',
      t173: '去添加流动性',
      t174: '猛士权证WOR项目简介',
      t175: '“好莱坞资本集团”作为首个提出“币股同一”概念并付诸实践的企业，其目的在于“开创全球股权经济新时代”。WOR猛士权证，是全球第一个将区块链去中心化特征有机融合元宇宙虚拟世界属性、3.0电影、文化型NFT、ChatGPT、实体产业、资本市场为一炉的数字化、通证化的《企业股权证》。',
      t176: '项目优势',
      t177: '机构投资专用钱包',
      t178: '事业发展钱包',
      t179: '合约发起人钱包',
      t180: '产业置换钱包',
      t181: '运营及管理团队钱包',
      t182: '合约代码',
      t183: '合作伙伴',
      t184: '预估收益',
      t185: '奖励不足！',
      t186: '存入成功！',
      t187: 'LP余额不足！',
      t188: '请输入交易所账号',
      t189: "交易所账号提交成功！",
      t190: '输入数量不能大于LP余额！',
      t191: '社区名称',
      t192: '认购记录',
      t193: '每月释放',
      t194: '认购',
      t195: '加入社区认购',
      t196: '社区代码',
      t197: '请输入',
      t198: '认购获得0wor(WOR锁仓十个月，每个月释放10%)',
      t199: '加入',
      t200: '当前剩余认购',
      t201: '认购价格',
      t202: '剩余释放',
      t203: '已释放',
      t204: '认购数量异常！',
      t205: '认购数量不能小于0！',
      t206: '认购数量不能大于剩余认购数！',
      t207: '提取数量',
      t208: '有奖锁仓',
      t209: '社区认购',
      t210: '认购数量',
      t211: '暂无数据',
      t212: '需要消耗',
      t213: 'USDT（WOR锁仓十个月，每个月释放10%）',
      t214: '暂未释放，请稍后再试！',
      t215: '请输入社区代码！',
      t216: '市值维护冷钱包',
      t217: '有奖竞答',
      t218: 'There are prizes',
      t219: 'WOR星球',
      t220: '排行榜',
      t221: '排名名次',
      t222: '钱包地址',
      t223: '助力次数',
      t224: '守护奖',
      t225: '活动结束后，最后十名用户，按照排名发放奖励',
      t226: '倒数名次',
      t227: '用户钱包地址',
      t228: '奖励',
      t229: 'Wor星球',
      t230: '助力WOR共识，瓜分百万大奖！',
      t231: '初始奖金池10万WOR。上限100万WOR。',
      t232: '玩法介绍',
      t233: '每花费5枚WOR权证，可获得一张助力票（助力票需要事先购买，活动结束后未使用的全额返还）。使用助力票可进行一次助力，并获得一个助力牌。助力牌编号从1开始顺延，最高编号为18万。',
      t234: '活动初始倒计时24小时，倒计时结束后，按照奖金分配规则，瓜分奖金。',
      t235: '倒计时规则：初始倒计时24小时，每助力一次，倒计时延长10s（助力冷却时间为30s）。倒计时结束，活动结束，结算奖金。',
      t236: '奖金规则：初始10万WOR，每获得一次助力，奖金池增加5每WOR。奖金池达到100万时，活动结束，结算奖金。',
      t237: '瓜分规则',
      t238: '一、激励奖',
      t239: '1、前10%助力的用户根据消耗助力牌，平均瓜分奖金池的15%',
      t240: "2、后10%助力的用户根据消耗助力牌，平均瓜分奖金池的15%",
      t241: '二、守护奖',
      t242: '活动结束时，最后10名用户参与助力的用户，获得守护大奖。',
      t243: '1、最后一名助力用户，获得奖金池3%',
      t244: '2、倒数第二名助力用户，获得奖金池2%',
      t245: '3、倒数第三名助力用户，获得奖金池1%',
      t246: '4、倒数4-5名，获得奖金池0.5%',
      t247: '5、倒数6-10名，获得奖金池0.2%',
      t248: '三、排行奖',
      t249: '助力次数最多的8名，获得排行大奖（次数相同则按照上榜时间计算排名）',
      t250: '1、第一名助力用户，获得奖金池3%',
      t251: '2、第二名助力用户，获得奖金池2%',
      t252: '3、第三名助力用户，获得奖金池1%',
      t253: '4、第四名助力用户，获得奖金池0.8%',
      t254: '5、第五名助力用户，获得奖金池0.6%',
      t255: '6、第六名助力用户，获得奖金池0.3%',
      t256: '7、第七名助力用户，获得奖金池0.2%',
      t257: '8、第八名助力用户，获得奖金池0.1%',
      t258: '四、分红奖',
      t259: '奖池剩余金额，按照助力牌持有情况，分配给未获得激励奖的用户。',
      t260: '注：账户上的每个助力牌分别计算奖金情况。',
      t261: '特殊规则',
      t262: '1、同一地址每次助力间隔为30秒。',
      t263: '2、活动结束后，未使用的助力票，将全部退还。',
      t264: '3、当奖池金额>18万时，可以一次使用5张助力票。获得5张助力牌（连号）。',
      t265: '当奖池金额>30万时，可以一次使用10张助力票。',
      t266: '当奖池金额>50万时。可以一次使用20张助力票。',
      t267: '当奖池金额>91万，多倍助力功能关闭。',
      t268: '使用多张助力票进行助力时，倒计时仅延长一次。',
      t269: '其他',
      t270: '因秉持公平公正原则，本次活动全程公开透明。主办方要求排行奖和守护奖的获得者，领奖时需提供本人真实身份信息，并全网公示，请参与者知悉。',
      t271: '参与助力',
      t272: '活动介绍',
      t273: '助力票',
      t274: '助力',
      t275: '票',
      t276: '排行榜',
      t277: '守护榜',
      t278: '暂无人助力',
      t279: '助力成功',
      t280: '游戏结束倒计时',
      t281: '最终大奖池',
      t282: '我的助力牌',
      t283: '查看',
      t284: '我的助力票',
      t285: '购买',
      t286: '我的奖金',
      t287: '购买助力票',
      t288: '购买数量',
      t289: '购买数量',
      t290: '购  买',
      t291: '助力牌记录',
      t292: '内容',
      t293: '助力牌票数：',
      t294: '牌子编码：',
      t295: '温馨提示',
      t296: '该奖金估算为当前活动结束后的参考数字，并不表示您的实际收益。可能会随活动进程进行变动。此参考值不包含排行奖和守护奖，排行奖和守护奖中奖名单将在活动结束后公示。',
      t297: '购买数量格式错误！',
      t298: '购买数量不能为空！',
      t299: '余额不足！',
      t300: '操作成功！',
      t301: '助力成功！',
      t302: '无法选择!',
      t303: '安全',
      t304: '密码',
      t305: '请输入密码',
      t306: '确定',
      t307: '确认密码',
      t308: '请再次输入密码',
      t309: '密码长度不能小于6位！',
      t310: '密码必须为纯数字！',
      t311: '密码长度不能小于6位！',
      t312: '密码必须为纯数字！',
      t313: '两次密码输入不一致！',
      t314: '第一次输入的密码为后续访问密码，请务必记住！',
      t315: '快来投票助我第一',
      t316: '编号：',
      t317: '排名',
      t318: '票数',
      t319: '分享',
      t320: '投票',
      t321: '团队人气赛',
      t322: '可以搜索您的队名或小队编号',
      t323: '编号：',
      t324: '票数：',
      t325: '票',
      t326: '分享',
      t327: '投票',
      t328: '加载更多',
      t329: '我也是有底线的~',

      t330: '欢乐拼团',
      t331: '创建小队',
      t332: '复制链接',
      t333: '复制链接',
      t334: '获得文字',
      t335: '操作',
      t336: '自己',
      t337: '退出',
      t338: '点击加号，上传小队照片和小队口号',
      t339: '账号信息记录',
      t340: '创建小队',
      t341: '填写小队名称',
      t342: '创建小队',
      t343: '参与活动',
      t344: '填写交易所账号',
      t345: '参与活动',
      t346: '上传',
      t347: '上传小队照片',
      t348: '小队口号',
      t349: '请输入小队口号(20字以内,上传后不可修改)',
      t350: '确  定'
    }
  }
})
