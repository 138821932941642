import 'vant/lib/style/base.less';
import '@/styles/base.scss';
(function (w, d) {
  const root = d.documentElement
  function getFontSizeScaleRate() {
    try {
      if (/(iphone|ipad|ipod)/i.test(window.navigator.userAgent)) {
        return 1
      }

      const $dom = document.createElement('div')
      $dom.setAttribute('style', 'font-size:12px;')
      document.body.appendChild($dom)
      // 计算出放大后的字体
      const scaledFontSize = parseFloat(
        window.getComputedStyle($dom, null).getPropertyValue('font-size'),
      )
      document.body.removeChild($dom)
      // 计算原字体和放大后字体的比例
      return 12 / scaledFontSize
    } catch (error) {
      return 1
    }
  }
  function setRootFontSize() {
    const w = root.clientWidth
    const x = w / 18.75
    root.style.fontSize = x * getFontSizeScaleRate() + 'px'
  }

  function setBodyFontSize() {
    if (d.body) {
      d.body.style.fontSize = 16 * getFontSizeScaleRate() + 'px'
    } else {
      d.addEventListener('DOMContentLoaded', setBodyFontSize)
    }
  }
  setBodyFontSize()
  setRootFontSize()
  w.addEventListener('resize', function () {
    setRootFontSize()
  })
  w.addEventListener('pageshow', function () {
    setRootFontSize()
  })
})(window, document)
