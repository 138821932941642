<template>
  <div class="container">
    <div class="header" :style="{backgroundColor:state.path=='/manghe'?'transparent':`#fff`}">
      <div class="left">
        <img src="@/assets/logo.png" alt="">
      </div>
      <div class="qiehuanbtn">
        {{state.address}}
      </div>
      <!-- <div class="right" @click.stop="openMenu">
        <img src="@/assets/menu.png" alt="">
        <div class="rightMenu" v-if="state.show">
          <div class="rightMenuBox">
            <ul>
              <li :class="{'active':state.path=='/home'}" @click.stop="gohome('/home')">
                <div>
                  <img :src="state.path=='/home'?require('@/assets/home.png'):require('@/assets/home2.png')" alt="">{{$t('t1')}}
                </div>
              </li>
              <li :class="{'active':state.path=='/aboutme'}" @click.stop="gohome('/aboutme')">
                <div>
                  <img :src="state.path=='/aboutme'?require('@/assets/guanyuwomen2.png'):require('@/assets/guanyuwomen.png')" alt="">{{$t('t2')}}
                </div>
              </li>
              <li :class="{'active':state.path=='/news'}" @click.stop="gohome('/news')">
                <div>
                  <img :src="state.path=='/news'?require('@/assets/xinwen2.png'):require('@/assets/xinwen.png')" alt="">{{$t('t3')}}
                </div>
              </li>
              <li :class="{'active':state.path=='/chanyemulu'}" @click.stop="gohome('/chanyemulu')">
                <div>
                  <img :src="state.path=='/chanyemulu'?require('@/assets/mulu2.png'):require('@/assets/mulu.png')" alt="">{{$t('t4')}}
                </div>
              </li>
              <li :class="{'active':state.path=='/nft'}" @click.stop="gohome('/nft')">
                <div>
                  <img :src="state.path=='/nft'?require('@/assets/nft2.png'):require('@/assets/nft.png')" alt="">{{$t('t5')}}
                </div>
              </li>
              <li :class="{'active':state.path=='/dianyingsan'}" @click.stop="gohome('/dianyingsan')">
                <div>
                  <img :src="state.path=='/dianyingsan'?require('@/assets/dianying32.png'):require('@/assets/dianying3.png')" alt="">{{$t('t6')}}
                </div>
              </li>
              <li :class="{'active':state.path=='/quanweifabu'}" @click.stop="gohome('/quanweifabu')">
                <div>
                  <img :src="state.path=='/quanweifabu'?require('@/assets/quanwei2.png'):require('@/assets/quanwei.png')" alt="">{{$t('t7')}}
                </div>
              </li>
              <li :class="{'active':state.path=='/quanzheng'}" @click.stop="gohome('/quanzheng')">
                <div>
                  <img :src="state.path=='/quanzheng'?require('@/assets/quanzheng2.png'):require('@/assets/quanzheng.png')" alt="">{{$t('t8')}}
                </div>
              </li>
              <li :class="{'active':state.path=='/zhuanqu'}" @click.stop="gohome('/zhuanqu')">
                <div>
                  <img :src="state.path=='/zhuanqu'?require('@/assets/zhuanqu2.png'):require('@/assets/zhuanqu.png')" alt="">{{$t('t136')}}
                </div>
              </li>
              <li :class="{'active':state.path=='/suocangzhuanqu'}" @click.stop="gohome('/suocangzhuanqu')">
                <div>
                  <img :src="state.path=='/suocangzhuanqu'?require('@/assets/suocangzhuanqu2.png'):require('@/assets/suocangzhuanqu.png')" alt="">{{$t('t137')}}
                </div>
              </li>
              <li :class="{'active':state.path=='/paihangbang'}" @click.stop="gohome('/paihangbang')">
                <div>
                  <img :src="state.path=='/paihangbang'?require('@/assets/phb2.png'):require('@/assets/phb.png')" alt="">排行榜
                </div>
              </li>
              <li :class="{'active':state.path=='/shouhujiang'}" @click.stop="gohome('/shouhujiang')">
                <div>
                  <img :src="state.path=='/shouhujiang'?require('@/assets/jinagbei2.png'):require('@/assets/jiangbei.png')" alt="">守护奖
                </div>
              </li>
            </ul>
            <div class="fanyibtn" @click.stop="onLangChange()">
              {{$t('t163')}}<span><img src="@/assets/qiehuan.png" alt=""></span>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="body">
      <div :class="state.path=='/manghe'?'routerviewTwo':'routerview'">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
    import { defineComponent,onMounted,reactive,watch} from 'vue';
    import {useRouter,useRoute} from 'vue-router';
    import {useStore} from 'vuex';
    import {Session} from '@/utils/storage'
    import { useI18n } from 'vue-i18n';
    import { Toast,Badge } from 'vant';
    import * as tp from 'tp-js-sdk'
    import {formatBankNumber} from '@/utils/tools';
    import Web3 from 'web3/dist/web3.min.js'
    export default defineComponent({
        components:{
          'van-badge':Badge
        },
        name: "footer",
        setup() {
          const router = useRouter();
          const route = useRoute();
          const store = useStore();

          let state = reactive({
            show:false,
            address:"00000000000000000000000000000000",
            rgba:1,
            path:'/suocangzhuanqu'
          })

          const {locale,t} = useI18n({
            inheritLocale: true,
            useScope: 'global'
          })

          const gohome=(path)=>{
            state.show = false;
            state.path = path;
            router.push({
              path:path
            })
          }

          const noKaiFang=()=>{
            Toast({
              message:t('t86'),
            });
          }

          const openMenu=()=>{
            state.show = !state.show;
          }

          ///语言切换
          const onLangChange=()=>{
            if(locale.value=='zh'){
              Session.set('lang','en');
              locale.value = 'en'
            }else{
              locale.value = 'zh'
              Session.set('lang','zh');
            }
            state.show = false;
          }
          const web3 = new Web3((window as any).ethereum);

          const connectToWeb3 = async () => {
            try {
              // 请求用户授权
              await (window as any).ethereum.enable();
              console.log('已连接到Web3');
          
              // 获取当前账户
              const accounts = await web3.eth.getAccounts();
              console.log('当前账户：', accounts[0]);
              state.address=formatBankNumber(accounts[0],5);
              Session.set('addressdata',accounts[0]);
              // web3.eth.getBalance(state.address,(err, res) => {
              // if (!err) {
              //     console.log("ETH余额==",res.toNumber()/Math.pow(10,18));
              // }
              // })
            } catch (error) {
              console.error('连接到Web3失败：', error);
            }
          }
          onMounted(async()=>{
            state.address=formatBankNumber(state.address,5);
            const isLogin = tp.isConnected();
            if (!isLogin){
              connectToWeb3();
            } else{
              const res = await tp.getCurrentWallet(1);
            console.log(res,'login-----------');
            // debugger
            if (!res || !res.result || !res.data){
                return false
            }else{
              let dataInfo = res.data||{};
              let address = dataInfo.address||'';
              state.address=formatBankNumber(address,5);
              Session.set('addressdata',address);
            }
            }
            state.path = route.path; 
            window.onscroll = function(){
              let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
              if(scrollTop>57.5){
                state.rgba = 1;
              }else{
                state.rgba = scrollTop/57.5
              }
            }
          })

          watch(()=>store.state.path,(n,o)=>{
            if(n!=0){
              console.log('1------',n)
              state.path = n;
            }else{
              state.path = o;
            }
          })

          return {
            gohome,
            openMenu,
            state,
            onLangChange,
            noKaiFang,
            locale,
            t
          }
        }
    })
</script>
<style lang="scss" scoped>
@import '@/styles/vars.scss';
.container{
  position: relative;
  background-color: #F8F8F8 ;
}

.leftMenu{
  position:fixed;
  z-index:99999;
  width:100%;
  top:63*$px;
  left:0;
  height:100vh;
  display: flex;
  flex-direction: row;
  .menuBox{
    background-color:#2d2d2d;
    width:80%;
  }
  .menuBoxRight{
    flex:1;
  }
}
.header{
  background-color:transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:12*$px 20*$px;
  position:fixed;
  z-index:999999;
  width:calc(100% - 40*$px);
  border-bottom:1*$px solid rgba(129, 114, 87, 0.3);
  img{
    width:26*$px;
  }
  .left{
    flex:1;
    img{
      width:24*$px;
      height:24*$px;
    }
  }
  .notice{
    margin-right:10*$px;
  }
  .qiehuanbtn{
    border:1*$px solid#81612F;
    opacity: 1;
    border-radius: 22*$px;
    text-align: center;
    font-size: 12*$px;
    height:30*$px;
    line-height:30*$px;
    font-weight:500;
    color:rgba(0, 0, 0, 0.7);
    margin-right:12*$px;
    padding:0 10*$px;
  }
  .right{
    display: flex;
    flex-direction:column;
    position: relative;
    img{
      width:20*$px;
      height:20*$px;
    }
    .rightMenu{
      position: absolute;
      top:0;
      right:0;
      margin-top:36*$px;
      text-align: left;
    }
  }
}
ul{
  padding:14*$px 10*$px;
  padding-bottom:0;
  li{
    font-size: 12*$px;
    font-weight:500;
    color: rgba(255, 219, 152, 0.7);
    border-radius: 8*$px;
    width:140*$px;
  }
  li:nth-child(1){
    margin-top:0px;
  }
}

.rightMenuBox{
  background: rgba(40, 37, 35, 0.7);
  position: relative;
  border-top-left-radius:12*$px;
  border-bottom-left-radius:12*$px;
  margin-right:-20*$px;
  li{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position:relative;
    height:44*$px;
    div{
      position: absolute;
      left:0;
      right:0;
      display: flex;
      flex-direction: row;
      align-items: center;
      width:100%;
      height:44*$px;
      opacity:0;
      animation: namescalc 0.3s linear forwards;
    }
    @keyframes namescalc {
      0% {
        top:-10*$px;
        opacity: 0;
      }

      100% {
        top:0*$px;
        opacity: 1;
      }
    }
    img{
      width:16*$px;
      height:16*$px;
      margin-right:6*$px;
      padding-left:17*$px;
    }
  }
  .fanyibtn{
    font-size: 14*$px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #CDB07B;
    padding-top:10*$px;
    padding-bottom:16*$px;
    padding-left:27*$px;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height:21*$px;
    border-top: 1*$px solid rgba(255, 219, 152, 0.15);
    margin-top:10*$px;
    span{
      width: 16*$px;
      height: 16*$px;
      margin-left:26*$px;
      display: flex;
      flex-direction: column;
      img{
        width: 16*$px;
        height: 16*$px;
      }
    }
  }
}
.body{
  .bodybg{
    position: absolute;
    width:100%;
  }
  .routerview{
    position: relative;
    width:100%;
    padding-top:50*$px;
  }
  .routerviewTwo{
    position: relative;
    width:100%;
    padding-top:-57.5*$px;
  }
}
.active{
  background: linear-gradient(180deg, #FFD89C 0%, #AD7A2E 99%);
  color: #FFF6E6;
  opacity: 0;
  animation: opciatyan 0.3s linear forwards;
}
@keyframes opciatyan {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>