import request from '@/utils/http';
export function signIn(params: object) {
	return request({
		url:'/tug/user/worxqaddress/loginAuth',
		method: 'post',
		data: params,
	});
}

export function signInHuanle(params: object) {
	return request({
		url:'/tug/user/wortuser/createUser',
		method: 'post',
		data: params,
	});
}