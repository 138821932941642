import './loadRoutes';
import qs from '@/utils/querystring'
import loader from '@/utils/loader'
import loadComponents from './loadComponents'
// 调试使用，正式环境debug=1强制开启控制台
const query = qs.parse(window.location.search)
if ((process.env.NODE_ENV == 'production' && query.debug == 1) || process.env.NODE_ENV == 'development') {
  //  loader('https://cdn.jsdelivr.net/npm/eruda').then(() => {
  //    window.eruda.init()
  //  })
}
// loader('https://cdn.jsdelivr.net/npm/eruda').then(() => {
//   window.eruda.init()
// })

////可以在加载路由之前提前加载某些方法

import './loadStyle'

export default function boot(app: any) {
  loadComponents(app);
  return app
}